import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { HospitalMenuEdit } from '../../../../components/Hospital/Management/Menu/HospitalMenuEdit';

export default () => (
  <Layout type="settings">
    <HospitalMenuEdit />
  </Layout>
);
